import { defineStore } from 'pinia'
import api from '@/shared/api'

export default defineStore('user', {
  state: () => ({
    loading: false,
    currentUser: window.app_data.current_user,

    firstName: '',
    lastName: '',
    email: '',
    password: '',
    error: '',

    validationRules: {
      email: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],

      firstName: [
        v => !!v || 'First name is required',
      ],

      lastName: [
        v => !!v || 'Last name is required',
      ],

      password: [
        v => !!v || 'Password is required',
        v => v.length >= 6 || 'Password must be at least 6 characters long'
      ]
    }
  }),

  actions: {
    async login(params) {
      console.log("LOGGING IN!...")
      const data = await api.login({
        email: this.email,
        password: this.password
      })
      console.log('request done')

      if (data.error) {
        const { error } = data.error
        this.error = error
      } else if (data.id) {
        location.href = "/app"
      }
    },

    async register(params) {
      const { firstName, lastName, email, password } = this

      const data = await api.register({
        first_name: firstName,
        last_name: lastName,
        email,
        password
      })

      if (data.error) {
        const { error } = data.error
        this.error = error
      } else if (data.id) {
        location.href = '/app'
      }
    },

    async updateUser(user) {
      this.currentUser = await api.updateUser(user)
    },

    // Mutations

    clearErrors() {
      this.errors = ''
    },

    setFirstName(fname) {
      this.firstName = fname
    },

    setLastName(lname) {
      this.lastName = lname
    },

    setEmail(email) {
      this.email = email
    },

    setPassword(password) {
      this.password = password
    }
  },
})
